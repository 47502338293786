export const generateUrl = ({
    cjEvent,
    svLink,
    cvLink,
    customLink,
    hostname,
    url,
  }) => {
    if (customLink) {
      return customLink;
    } 
    if (cvLink) {
      return `https://shopstyle.it/l/${cvLink}`;
    } 
    if (svLink) {
      return `https://sovrn.co/${svLink}`;
    } 
    if (cjEvent) {
      return `https://${hostname}/?utm_medium=referral&utm_source=Cleo+Cards+inc&cjevent=${cjEvent}&cjdata=MXxOfDB8WXww`;
    } 
    if (url) {
      return `https://${url}`;
    } 
    
    return null; 
  };
  