import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';

import Navbar from '../../components/Navbar';
import Content from '../../components/Content';
import { BreadcrumbHeader } from '../../components/PageHeader';
import Card from '../../components/Card';
import Footer from '../../components/Footer';

import { getMyCodes, deleteCode, unlistCode } from '../../api';
import { centsToDollars } from '../../utils/currency';
import { classNames } from '../../utils/dom';

import CardModal from './CardModal';

const sortCodes = (codes) => {
  const rejectedCodes = [];
  const listedCodes = [];
  const sellableCodes = [];
  const soldCodes = [];
  const usedCodes = [];
  const pendingCodes = [];
  // const unsellableCodes = [];

  for (const code of codes) {
    // if (!code.store.supported) {
    //   unsellableCodes.push(code);
    //   continue;
    // }

    if (code.used) {
      usedCodes.push(code);
      continue;
    }

    if (code.rejected) {
      rejectedCodes.push(code);
      continue;
    }

    if (code.purchased) {
      soldCodes.push(code);
      continue;
    }

    if (code.listed) {
      listedCodes.push(code);
      continue;
    }

    if (code.manualValidation && !code.listed && !code.rejected) {
      pendingCodes.push(code);
      continue;
    }

    sellableCodes.push(code);
  }

  return [
    ...rejectedCodes,
    ...listedCodes,
    ...sellableCodes,
    ...pendingCodes,
    ...soldCodes /*, ...unsellableCodes */,
    ...usedCodes,
  ];
};

const YourCards = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [cards, setCards] = React.useState([]);
  const [cardModalOpen, setCardModalOpen] = React.useState(false);
  const [currentCard, setCurrentCard] = React.useState(null);

  React.useEffect(() => {
    getAccessTokenSilently()
      .then(getMyCodes)
      .then((codes) => {
        // TODO: in the future, need to move sorting to BE, when we have pagination
        const sortedCodes = sortCodes(codes);
        setCards(sortedCodes);
      });
  }, [getAccessTokenSilently]);

  const handleViewCard = (details) => {
    setCurrentCard(details);
    setCardModalOpen(true);
  };

  const handleSellCard = ({ id, storeId }) => {
    navigate(`/sell-gift-cards/${storeId}/${id}`);
  };

  const handleRemoveCard = async () => {
    const token = await getAccessTokenSilently();
    await deleteCode(token, currentCard.id);

    const newCards = cards.filter((card) => card.id !== currentCard.id);
    setCards(newCards);

    setCardModalOpen(false);
    setCurrentCard(null);
  };

  const handleUnlistCard = async () => {
    const token = await getAccessTokenSilently();
    await unlistCode(token, currentCard.id);

    const cardIndex = cards.findIndex((card) => card.id === currentCard.id);
    const newCard = { ...cards[cardIndex], listed: false };
    const newCards = sortCodes([
      ...cards.slice(0, cardIndex),
      newCard,
      ...cards.slice(cardIndex + 1),
    ]);
    setCards(newCards);

    setCardModalOpen(false);
    setCurrentCard(null);
  };

  const actionButtons = ({
    id,
    storeId,
    storeTitle,
    storeImageUrl,
    storeUrl,
    balance,
    rejected,
    purchased,
    used,
    listed,
    // supported,
    pending,
    code,
    expiresAt,
  }) => {
    return (
      <div className="flex gap-2.5">
        <button
          type="button"
          className="rounded-md bg-indigo-50 px-3 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-100 flex-1"
          onClick={() =>
            handleViewCard({
              id,
              storeId,
              storeTitle,
              storeImageUrl,
              storeUrl,
              balance: centsToDollars(balance),
              rejected,
              purchased,
              used,
              listed,
              // supported,
              pending,
              code,
              expiresAt,
            })
          }
        >
          View{purchased ? ' - Sold Card' : used ? ' - Used Card' : ''}
        </button>
        {rejected && (
          <button
            type="button"
            className={classNames(
              'rounded-md px-3 py-2 text-sm font-medium shadow-sm text-white bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex-1'
            )}
            onClick={() => handleSellCard({ id, storeId })}
          >
            Update
          </button>
        )}
        {!purchased && !listed && !pending && !rejected && (
          <button
            type="button"
            // disabled={!supported}
            className={classNames(
              'rounded-md px-3 py-2 text-sm font-medium shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex-1',
              true // supported
                ? 'text-white bg-indigo-600 hover:bg-indigo-500'
                : 'text-gray-600 bg-gray-300'
            )}
            onClick={() => handleSellCard({ id, storeId })}
          >
            Sell
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      <Navbar />
      <Content>
        <div className="w-full max-w-7xl mx-auto space-y-3">

          <BreadcrumbHeader
            items={[
              { to: '/', label: 'Home' },
              { to: '#', label: 'Your Cards' },
            ]}
            title="Gift Cards Wallet"
            actionButtonTitle="Add Gift Card"
            actionButtonLink="/cards/new"
          />
          <ul className="grid grid-cols-1 gap-6 my-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {cards.map(
              ({
                id,
                balance,
                storeId,
                code,
                expiresAt,
                rejected,
                purchased,
                used,
                listed,
                manualValidation,
                store: {
                  title: storeTitle,
                  imageUrl: storeImageUrl,
                  url: storeUrl,
                  // supported,
                },
              }) => (
                <li
                  key={id}
                  className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
                >
                  <Card
                    storeTitle={storeTitle}
                    storeImageUrl={storeImageUrl}
                    balance={centsToDollars(balance)}
                    rejected={rejected}
                    purchased={purchased}
                    used={used}
                    listed={listed}
                    pending={manualValidation && !listed && !rejected}
                    // supported={supported}
                    actionButtons={actionButtons({
                      id,
                      storeId,
                      storeTitle,
                      storeImageUrl,
                      storeUrl,
                      balance,
                      rejected,
                      purchased,
                      used,
                      listed,
                      pending: manualValidation && !listed && !rejected,
                      // supported,
                      code,
                      expiresAt,
                    })}
                  />
                </li>
              )
            )}
          </ul>
          <CardModal
            open={cardModalOpen}
            onOpen={setCardModalOpen}
            onSell={() => handleSellCard(currentCard)}
            onRemove={handleRemoveCard}
            onUnlist={handleUnlistCard}
            details={currentCard}
          />
        </div>
      </Content>
      <Footer />
    </>
  );
};

export default withAuthenticationRequired(YourCards);
