// components/DealDetails.js
import React, { useState } from "react";
import {
  FiArrowRight,
  FiExternalLink,
  FiCopy,
  FiCheck,
  FiArrowUpRight,
} from "react-icons/fi";
import { Card } from "../../../ui";
import ChromWeb from "../../../assets/images/chrome-web.png";
import PopUp from "../../../assets/images/pop-up.png";
import Rating from "../../../assets/images/cleo-rating.png";
import { LuArrowUpRight } from "react-icons/lu";
import { generateUrl } from "../../../utils/generateUrl";
import moment from "moment";
import { centsToDollars } from "../../../utils/currency";
import EmptyState from "../../../components/EmptyState";
import tagImage from "../../../assets/images/Tag.svg";

import AvailImg from "../../../assets/images/avail-Img.png";
import CloseButton from "../../../ui/CloseButton";
import EmailForm from "./EmailBox";
import CustomScrollbar from "../../../components/Scrollbar";

const DealDetails = ({ deal, isMobile = false, onClose }) => {
  const [copiedCode, setCopiedCode] = useState(null);

  const startDate = moment(deal.startDate);
  const endDate = moment(deal.endDate);

  const formattedStartDate = startDate.format("ddd, DD MMM ");
  const formattedEndDate = endDate.format("ddd, DD MMM");

  const copyToClipboard = (code, id) => {
    navigator.clipboard.writeText(code);
    setCopiedCode(id);
    setTimeout(() => setCopiedCode(null), 3000);
  };

  const partnerUrl = (url) => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    }
    return `https://${url}`;
  };

  const calculateSavingsAmount = (balance, price) => {
    const savings = balance - price;
    return Math.max(0, savings);
  };

  return (
    <div className=" p-6 rounded-lg space-y-5">
      <div
        className={`sticky md:static top-0 pt-5 md:pt-0 bg-[#f8f8f8] md:bg-transparent pb-2 z-10`}
      >
        {" "}
        {/* Add sticky class here */}
        {isMobile && (
          <div className="flex items-center justify-between gap-3 w-full  mb-2">
            <div className=" w-[90%]">
              <a
                href={
                  deal.partnerLink
                    ? partnerUrl(deal.partnerLink)
                    : generateUrl(deal.store)
                }
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-start gap-2 max-w-fit border-b border-[#4A3DC3]"
              >
                <p className="font-semibold text-[#4A3DC3] max-w-[95%] truncate text-base ">
                  {deal.store.title} - {deal.dealTitle}
                </p>
                <div className="w-[5%]">
                  <FiExternalLink className="h-5 w-5" color="#4A3DC3" />

                </div>

              </a>
            </div>
            <div className="bg-[#e0e0e0] p-1 h-fit rounded-full">
              <CloseButton
                onClick={onClose}
              // className="drawer-close-button" // Add custom class for styling
              />
            </div>
          </div>
        )}
        {!isMobile && (
          <h2 className="text-lg font-semibold text-[#212121] w-full truncate ">
            {deal.store.title} - {deal.dealTitle}
          </h2>
        )}
        <p className="text-xs text-[#5C5C5C] font-semibold ">{`${formattedStartDate} - ${formattedEndDate}`}</p>
        {!isMobile && (
          <a
            href={
              deal.partnerLink
                ? partnerUrl(deal.partnerLink)
                : generateUrl(deal.store)
            }
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium text-[#4A3DC3] border-b border-[#4A3DC3] w-fit text-sm flex items-center"
          >
            Shop now at {deal?.store?.url?.toLowerCase()}
            <FiExternalLink className="h-4 w-4 ml-1" />
          </a>
        )}
      </div>

      <div className="space-y-3">
        {!isMobile && (
          <div className="rounded-full bg-[#F2F2F2] border-[#E5E7EB]  px-2 mb-2 py-1 border w-fit">
            <h3 className="font-semibold text-xs">Available Codes</h3>
          </div>
        )}
        <CustomScrollbar maxHeight={200} >

          <Card className="space-y-2 border-none bg-transparent  shadow-none">
            {deal.coupons.length > 0 ? (
              deal.coupons.map((codeItem, index) => (
                <div
                  key={index}
                  className="flex mb-2 justify-between  items-center"
                >
                  <p className="text-sm font-medium text-gray-900">
                    {codeItem.title}
                  </p>
                  {codeItem.code ? (
                    <button
                      className={
                        copiedCode === index
                          ? "bg-[#4a3dc3] text-xs text-white flex items-center gap-2 px-3 py-2 rounded-full border"
                          : "text-gray-900 border-[#E8E8E8]  bg-white text-xs flex items-center gap-2 px-3 py-2 rounded-full border"
                      }
                      onClick={() => copyToClipboard(codeItem.code, index)}
                    >
                      {copiedCode === index ? (
                        <>
                          Copied!
                          <FiCheck className="h-3 w-3 mr-2" />
                        </>
                      ) : (
                        <>
                          {codeItem.code}
                          <FiCopy className="h-3 w-3 ml-2" />
                        </>
                      )}
                    </button>
                  ) : (
                    <div className="text-gray-900 border-[#E8E8E8]  bg-white text-xs flex items-center gap-2 px-3 py-2 rounded-full border">NO CODE NEEDED</div>
                  )}
                </div>
              ))
            ) : (
              <p className="text-sm text-gray-500">No codes available.</p>
            )}
          </Card>
        </CustomScrollbar>

      </div>

      <div className="space-y-3">
        {!isMobile && (
          <button>
            <a
              href={process.env.REACT_APP_EXTENSION_URL}
              target="__blank"
              className="font-medium text-[#4A3DC3] border-b border-[#4A3DC3] w-fit text-sm flex items-center"
            >
              Download Cleo to stack savings
              <FiArrowUpRight className="mr-2 h-4 w-4" />
            </a>
          </button>
        )}
        <div className="hidden md:block">
          <div className="flex items-start  gap-2 w-full">
            <CustomScrollbar maxHeight={360} width="w-4/5">

              <div className=" py-2 pt-0">
                {Array.isArray(deal?.store?.codes) &&
                  deal?.store?.codes.length > 0 ? (
                  deal.store.codes.map((card, index) => (
                    <Card
                      key={index}
                      className="mb-4 p-3 cursor-pointer bg-white group hover:bg-[#4a3dc3] hover:text-white shadow-none border"
                    >
                      <h3 className="font-semibold text-base  mb-2 group-hover:text-white">
                        Save
                        {centsToDollars(
                          calculateSavingsAmount(card.balance, card.price)
                        )}
                      </h3>
                      <div className="flex justify-between text-sm">
                        <span className="text-xs text-[#667085] group-hover:text-white">
                          Gift Card Amount
                        </span>
                        <span className="text-xs text-[#667085] group-hover:text-white">
                          {centsToDollars(card.balance)}
                        </span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-xs text-[#667085] group-hover:text-white">
                          What you pay
                        </span>
                        <span className="text-xs text-[#667085] group-hover:text-white">
                          {centsToDollars(card.price)}
                        </span>
                      </div>
                    </Card>
                  ))
                ) : (
                  <div className="w-full justify-center flex">
                    <EmptyState
                      img={tagImage}
                      heading="No Cards Found"
                      subHeading={`No card found in marketplace for ${deal.store.title}`}
                      button={true}
                      buttonText="Browse Marketplace"
                    />
                  </div>
                )}
              </div>
            </CustomScrollbar>


            <Card className="bg-img text-white w-[45%] p-4 ">
              <a href={process.env.REACT_APP_EXTENSION_URL} target="__blank">
                <div className="p-0 space-y-2 flex items-center justify-center flex-col">
                  <img
                    src={PopUp}
                    alt=" Preview"
                    className=" h-52  w-52 object-cover"
                  />
                  <img
                    src={ChromWeb}
                    alt=" Preview"
                    className="w-full  object-cover"
                  />
                  <img
                    src={Rating}
                    alt=" Preview"
                    className="w-full  object-cover"
                  />

                  <button
                    variant="secondary"
                    className="w-full text-xs bg-transparent text-white flex items-center gap-1 justify-center"
                  >
                    Download Extension <LuArrowUpRight className=" h-5 w-5" />
                  </button>
                </div>
              </a>
            </Card>
          </div>
        </div>
      </div>

      {isMobile && (
        <div className="mt-6">
          <Card className="p-3 shadow-none border bg-white ">
            <p className="text-sm mb-2 text-black font-medium">
              Save additional $50 with Cleo Cards
            </p>

            <EmailForm />
            <p className="text-xs text-gray-500 mb-4">
              Only available on Google Chrome Desktop
            </p>
            <div className="flex items-center space-x-2">
              <img src={AvailImg} alt="avail img " className="w-44" />
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default DealDetails;
