import React from "react";

const CustomScrollbar = ({ children, maxHeight, width }) => {
    return (
        <div
            className={`custom-scrollbar  overflow-y-auto p-2 hover:scrollbar-visible ${width}`} // Use the width prop
            style={{
                maxHeight: maxHeight || '300px', // Default maxHeight if none provided
            }}
        >
            {children}
        </div>
    );
};

export default CustomScrollbar;