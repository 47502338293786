import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';

import Button from '../../components/Button';
import Combobox from '../../components/Combobox';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { BreadcrumbHeader } from '../../components/PageHeader';
import { Input, DateInput } from '../../components/Input';
import { useNotification } from '../../components/Notification';

import { getAllStores, uploadCode } from '../../api';
import { dollarsToCents } from '../../utils/currency';
import { isValidDate, isPastDate } from '../../utils/date';
import { getStoreImage } from '../../utils/image';

import ContentWrapper from './styles';

const AddCard = () => {
  const navigate = useNavigate();
  const showNotification = useNotification();
  const { getAccessTokenSilently, user } = useAuth0();

  const [stores, setStores] = React.useState(null);
  const [selectedStore, setSelectedStore] = React.useState(null);

  React.useEffect(() => {
    getAllStores(true).then((data) =>
      setStores(data.sort((a, b) => (a.title < b.title ? -1 : 1)))
    );
  }, []);

  const [formData, setFormData] = React.useState({
    code: '',
    pin: '',
    balance: '',
    expiresAt: '',
  });
  const [processing, setProcessing] = React.useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;

    const newFormData = { ...formData, [id]: value };

    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedStore) {
      return showNotification({
        title: 'Choose store',
        description: 'Please choose the store that your card belongs to',
        type: 'error',
      });
    }

    if (formData.expiresAt) {
      if (!isValidDate(formData.expiresAt)) {
        return showNotification({
          title: 'Update expiration date',
          description: 'Invalid input syntax please make sure its “MM/DD/YYYY”',
          type: 'error',
        });
      }

      if (isPastDate(formData.expiresAt)) {
        return showNotification({
          title: 'Expired Card',
          description: 'This gift card has expired. Please enter a valid card.',
          type: 'error',
        });
      }
    }

    try {
      setProcessing(true);

      const token = await getAccessTokenSilently();

      const data = {
        ...formData,
        email: user.email,
        balance: dollarsToCents(formData.balance),
      };

      if (selectedStore.id) {
        data.storeId = selectedStore.id;
      } else {
        data.storeTitle = selectedStore.title;
      }
      await uploadCode(token, data);

      navigate('/cards');
    } catch (error) {
      console.log(error);
      return showNotification({
        title: 'Error',
        description: 'Sorry something went wrong!',
        type: 'error',
      });
    } finally {
      setProcessing(false);
    }
  };

  if (!stores) return null;

  return (
    <>
      <Navbar />
      <Content>
        <div className="w-full max-w-7xl mx-auto space-y-3">
          <ContentWrapper>
            <BreadcrumbHeader
              items={[
                { to: '/', label: 'Home' },
                { to: '/cards', label: 'Your Cards' },
                { to: '#', label: 'Add Gift Card' },
              ]}
              title="Add Gift Card"
            />
            <div className="content-body">
              <div className="left">
                <img
                  src={getStoreImage(selectedStore?.imageUrl)}
                  alt="Gift Card"
                />
              </div>
              <form className="right" onSubmit={handleSubmit}>
                <div className="heading">
                  <h3>Gift Card Information</h3>
                  <p>We do not accept cards that expire or incur fees.</p>
                </div>
                <div className="half-container">
                  <Combobox
                    allowNewOption
                    options={stores}
                    selectedOption={selectedStore}
                    label="Store *"
                    onChange={setSelectedStore}
                  />
                </div>
                <div className="code-container">
                  <Input
                    id="code"
                    label="Gift Card Serial Number *"
                    required
                    onChange={handleChange}
                  />
                  {!selectedStore?.isShopify && (
                    <Input id="pin" label="PIN" onChange={handleChange} />
                  )}
                </div>
                <div className="highlight-box">
                  <h3>eGift Card</h3>
                  <p>
                    eliminates shipping of a physical gift card and reduces your
                    listing fees. Please note that many eGift cards are for online
                    use only.
                  </p>
                </div>
                <div className="prices-container">
                  <Input
                    id="balance"
                    label="Current Balance *"
                    type="number"
                    step=".01"
                    required
                    onChange={handleChange}
                  />
                  <DateInput
                    id="expiresAt"
                    label="Expiration Date"
                    onChange={handleChange}
                  />
                </div>
                <div className="submit-container">
                  <p>
                    By submitting this listing I fully agree with Terms and
                    Conditions
                  </p>
                  <Button disabled={processing} type="submit">
                    {processing ? 'Processing...' : 'Submit'}
                  </Button>
                </div>
              </form>
            </div>
          </ContentWrapper>
        </div>
      </Content>
      <Footer />
    </>
  );
};

export default withAuthenticationRequired(AddCard);
