// components/DealCard.js
import React, { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { Card } from "../../../ui";
import { getStoreImage } from "../../../utils/image";
import PopUp from "../../../assets/images/gift-card.png";

import moment from "moment";
const DealCard = ({ deal, onClick, isSelected }) => {

  const [imageSrc, setImageSrc] = useState(deal?.store.imageUrl || PopUp);

  const startDate = moment(deal.startDate);
  const endDate = moment(deal.endDate);

  const formattedStartDate = startDate.format("DD MMM ");
  const formattedEndDate = endDate.format("DD MMM ");

  return (
    <Card
      className={`mb-4 h-24  hover:shadow-lg transition-shadow cursor-pointer ${isSelected ? "ring-2 ring-purple-600" : ""
        }`}
      onClick={onClick}
    >
      <div className="flex items-stretch w-full">
        <div className=" rounded-l-md w-[55%] ">
          <img
            src={imageSrc}
            alt="Card"
            className="h-24 w-full rounded-l-md object-cover"
            onError={() => setImageSrc(PopUp)}
          />
        </div>
        <div className="w-[40%] flex items-center justify-center text-center gap-2">
          <div className="flex-grow flex flex-col justify-center p-4">
            <h3 className="font-medium text-xs text-[#4A3DC3] line-clamp-2">
              {deal.dealTitle}
            </h3>
            <p className="text-xs font-medium text-black">{`${formattedStartDate} - ${formattedEndDate}`}</p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DealCard;
