import React from "react";
import { useLoaderData } from "react-router-dom";
import Content from "../../components/Content";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { BreadcrumbHeader } from "../../components/PageHeader";
import { getAllStores, getAllCodes } from "../../api";
import CardTable from "./CardTable";

export async function loader({ params }) {
  const stores = await getAllStores(true);
  let store = null;
  if (params.storeId) {
    store = stores.find((s) => s.id === params.storeId);
  }
  const reloadlyStores = stores.filter((x) => x.reloadlyId);
  return { store, reloadlyStores };
}

const Buy = () => {
  const { store, reloadlyStores = [] } = useLoaderData() ?? {};

  const [codes, setCodes] = React.useState([]);
  const [stores, setStores] = React.useState([]);

  const breadcrumbItems = React.useMemo(() => {
    const items = [
      { to: "/", label: "Home" },
      { to: "/buy-gift-cards", label: "Buy" },
    ];

    if (store) {
      items.push({ to: `/buy-gift-cards/${store.id}`, label: store.title });
    }

    return items;
  }, [store]);

  React.useEffect(() => {
    getAllCodes().then((result) => {
      const storesMap = {};
      const newCodes = [];

      // TODO: move this logic to the API
      // const res = [
      //   ...result,
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -1,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "sephora",
      //       title: "Sephora",
      //     },
      //     storeId: "sephora",
      //     storeTitle: "Sephora",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -2,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "columbia",
      //       title: "Columbia",
      //     },
      //     storeId: "columbia",
      //     storeTitle: "Columbia",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -3,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "lowes",
      //       title: "Lowe's",
      //     },
      //     storeId: "lowes",
      //     storeTitle: "Lowes",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -4,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "nordstrom",
      //       title: "Nordstrom",
      //     },
      //     storeId: "nordstrom",
      //     storeTitle: "Nordstrom",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -5,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "saksfifthavenue",
      //       title: "Saks Fifth Avenue",
      //     },
      //     storeId: "saksfifthavenue",
      //     storeTitle: "Saks Fifth Avenue",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -6,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "target",
      //       title: "Target",
      //     },
      //     storeId: "target",
      //     storeTitle: "Target",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -7,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "macys",
      //       title: "Macy's",
      //     },
      //     storeId: "macys",
      //     storeTitle: "Macy's",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -8,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "hnm",
      //       title: "H&M",
      //     },
      //     storeId: "hnm",
      //     storeTitle: "H&M",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -9,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "gap",
      //       title: "Gap",
      //     },
      //     storeId: "gap",
      //     storeTitle: "Gap",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -10,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "bananarepublic",
      //       title: "Banana Republic",
      //     },
      //     storeId: "bananarepublic",
      //     storeTitle: "Banana Republic",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -11,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "wayfair",
      //       title: "Wayfair",
      //     },
      //     storeId: "wayfair",
      //     storeTitle: "Wayfair",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -12,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "homegoods",
      //       title: "HomeGoods",
      //     },
      //     storeId: "homegoods",
      //     storeTitle: "HomeGoods",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -13,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "kohls",
      //       title: "Kohl's",
      //     },
      //     storeId: "kohls",
      //     storeTitle: "Kohl's",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -14,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "deltaairlines",
      //       title: "Delta Air Lines",
      //     },
      //     storeId: "deltaairlines",
      //     storeTitle: "Delta Air Lines",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -15,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "adidas",
      //       title: "Adidas",
      //     },
      //     storeId: "adidas",
      //     storeTitle: "Adidas",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -16,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "athleta",
      //       title: "Athleta",
      //     },
      //     storeId: "athleta",
      //     storeTitle: "Athleta",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -17,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "dickssportinggoods",
      //       title: "Dick's Sporting Goods",
      //     },
      //     storeId: "dickssportinggoods",
      //     storeTitle: "Dick's Sporting Goods",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -18,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "crateandbarrel",
      //       title: "Crate&Barrel",
      //     },
      //     storeId: "crateandbarrel",
      //     storeTitle: "Crate&Barrel",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -19,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "ultabeauty",
      //       title: "Ulta Beauty",
      //     },
      //     storeId: "ultabeauty",
      //     storeTitle: "Ulta Beauty",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -20,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "guitarcenter",
      //       title: "Guitar Center",
      //     },
      //     storeId: "guitarcenter",
      //     storeTitle: "Guitar Center",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -21,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "americaneagle",
      //       title: "American Eagle",
      //     },
      //     storeId: "americaneagle",
      //     storeTitle: "American Eagle",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -22,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "thecontainerstore",
      //       title: "The Container Store",
      //     },
      //     storeId: "thecontainerstore",
      //     storeTitle: "The Container Store",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -23,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "tjx",
      //       title: "TJX",
      //     },
      //     storeId: "tjx",
      //     storeTitle: "TJX",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -24,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "southwestairlines",
      //       title: "Southwest Airlines",
      //     },
      //     storeId: "southwestairlines",
      //     storeTitle: "Southwest Airlines",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -25,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "nike",
      //       title: "Nike",
      //     },
      //     storeId: "nike",
      //     storeTitle: "Nike",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -26,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "amazon",
      //       title: "Amazon",
      //     },
      //     storeId: "amazon",
      //     storeTitle: "Amazon",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -27,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "zappos",
      //       title: "Zappos.com",
      //     },
      //     storeId: "zappos",
      //     storeTitle: "Zappos.com",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -28,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "callawaygolf",
      //       title: "Callaway Golf",
      //     },
      //     storeId: "callawaygolf",
      //     storeTitle: "Callaway Golf",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -29,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "cakebeauty",
      //       title: "Cake Beauty",
      //     },
      //     storeId: "cakebeauty",
      //     storeTitle: "Cake Beauty",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -30,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "underarmour",
      //       title: "Under Armour",
      //     },
      //     storeId: "underarmour",
      //     storeTitle: "Under Armour",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -31,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "gamestop",
      //       title: "GameStop",
      //     },
      //     storeId: "gamestop",
      //     storeTitle: "GameStop",
      //     validatedAt: null,
      //   },
      //   {
      //     balance: 2500,
      //     code: "################",
      //     discount: 14,
      //     id: -32,
      //     pin: "########",
      //     price: 2150,
      //     store: {
      //       id: "officedepot",
      //       title: "Office Depot",
      //     },
      //     storeId: "officedepot",
      //     storeTitle: "Office Depot",
      //     validatedAt: null,
      //   },
      // ];
      const codeIndex = result.length;

      const reloadlyStoresCodes = reloadlyStores.map((item, index) => {
        const discount = item.reloadlyPercentage;
        const balance = 2500;
        const price = balance - (balance * discount) / 100;
        return {
          balance: balance,
          code: "################",
          discount: discount,
          id: codeIndex - index,
          pin: "########",
          price: price,
          store: {
            id: item.id,
            title: item.title,
          },
          storeId: item.id,
          storeTitle: item.title,
          validatedAt: null,
        };
      });
      const res = [...result, ...reloadlyStoresCodes];
      for (let i = 0; i < res.length; i++) {
        const code = res[i];
        const { storeId, store, balance, price } = code;

        storesMap[storeId] = store;
        newCodes.push({
          ...code,
          storeTitle: store.title,
          discount: ((balance - price) / balance) * 100,
        });
      }
      setStores(Object.values(storesMap));
      setCodes(newCodes);
    });
  }, []);

  return (
    <>
      <Navbar />
      <Content>
      <div className="w-full max-w-7xl mx-auto space-y-3">

        <BreadcrumbHeader
          items={breadcrumbItems}
          title="Discount Gift Cards"
          subtitleEl={
            <p className="mt-2 text-base text-gray-500">
              Shop with the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={process.env.REACT_APP_EXTENSION_URL}
                className="underline text-gray-700"
              >
                Cleo chrome extension
              </a>{" "}
              at these stores to unlock savings now
            </p>
          }
        />
        <CardTable codes={codes} stores={stores} currentStore={store} />
        {store && (
          <p className="mt-8 text-base text-gray-700">
            {store.descriptionForBuy ??
              `You can purchase ${store.title} Gift Cards here.`}
          </p>
        )}
        </div>
      </Content>
      <Footer />
    </>
  );
};

export default Buy;
