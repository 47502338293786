// EmailForm.js
import React, { useState } from "react";
import { TbSend2 } from "react-icons/tb";
import { zapierWebHook } from "../../../api";
function EmailForm() {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
    } else {
      setError("");

      try {
          await zapierWebHook(email);
          setIsSubmitted(true)
          setEmail('')
          setTimeout(() => {
            setIsSubmitted(false);
        }, 2000); // 2000 milliseconds = 2 seconds
      } catch (error) {
        console.error("Error submitting email:", error);
        setError(
          "An error occurred while submitting your email. Please try again."
        );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="relative flex items-center mb-2">
        <input
          type="email"
          placeholder="Enter Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={`w-full pr-10 px-4 py-3 border-[#e5e7eb] text-xs bg-white rounded-md border text-gray-700 focus:outline-none focus:ring-2 ${
            error ? "focus:ring-red-500 border-red-500" : "focus:ring-[#4a3dc3]"
          } transition duration-300 ease-in-out`}
        />
        <button
          type="submit"
          className="absolute right-3 text-gray-400 hover:text-gray-600 transition duration-300 ease-in-out"
          aria-label="Submit email"
        >
          <TbSend2 className="h-6 w-6" color="#4a3dc3" />
        </button>
      </div>
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
      {isSubmitted && <p className="text-green-300 text-xs mt-1">Email submitted successfully:</p>}
    </form>
  );
}

export default EmailForm;
