import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../components/Button';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Card from '../../components/Card';
import { SearchInput } from '../../components/Input';
import { PageHeader } from '../../components/PageHeader';

import { getAllStores } from '../../api';

const Sell = () => {
  const [stores, setStores] = React.useState(null);
  const [filteredStores, setFilteredStores] = React.useState(null);
  const [query, setQuery] = React.useState('');

  React.useEffect(() => {
    getAllStores(true).then((data) => {
      setStores(data);
      setFilteredStores(data);
    });
  }, []);

  const handleChangeQuery = (e) => {
    const { value } = e.target;
    setQuery(value);

    if (!value) {
      setFilteredStores(stores);
    } else {
      const trimmed = value.toLowerCase().trim();
      const filtered = stores.filter(
        ({ id, title }) =>
          id.toLowerCase().indexOf(trimmed) > -1 ||
          title.toLowerCase().indexOf(trimmed) > -1
      );
      setFilteredStores(filtered);
    }
  };

  // TODO: show proper loading indicator
  if (!filteredStores) return null;

  return (
    <>
      <Navbar />
      <Content>
      <div className="w-full max-w-7xl mx-auto space-y-3">

        <PageHeader
          title="Sell Gift Cards Fast"
          subtitle="Earn cash for unwanted gift cards and store credit."
        />
        <SearchInput cvalue={query} onChange={handleChangeQuery} />
        <ul className="grid grid-cols-1 gap-6 my-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {filteredStores.map(({ id, title, description, imageUrl }) => (
            <li
              key={id}
              className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
            >
              <Card
                isStoreCard
                storeId={id}
                storeTitle={title}
                storeSubtitle={description}
                storeImageUrl={imageUrl}
                actionButtons={
                  <Link className="w-full block" to={`/sell-gift-cards/${id}`}>
                    <Button>Sell {title} Gift Card</Button>
                  </Link>
                }
              />
            </li>
          ))}
        </ul>
        </div>
      </Content>
      <Footer />
    </>
  );
};

export default Sell;
