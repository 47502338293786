import React from "react";

const EmptyState = ({
  img,
  heading,
  subHeading,
  button,
  buttonText,
  imgWidth = "w-52",
  imgHeight = "h-48",
}) => {
  return (
    <div className="space-y-3 flex justify-center flex-col items-center">
      <img src={img} alt="empty tag" className={`${imgWidth} ${imgHeight}`} />

      {heading && (
        <h2 className="font-semibold text-base  text-[#212121] ">{heading}</h2>
      )}
      {subHeading && (
        <h2 className="font-normal text-xs  text-black">{subHeading}</h2>
      )}
      {button && (
        <a target="__blank" href="/buy-gift-cards">
          <button
            type="button"
            className=" bg-[#4A3DC3] broder text-white font-semibold text-xs border-[#4A3DC3] rounded-full px-6 py-3"
          >
            {buttonText}
          </button>
        </a>
      )}
    </div>
  );
};

export default EmptyState;
