import React, { forwardRef, useEffect } from 'react'
import classNames from 'classnames'
import { useTabs } from './context'

const TabList = forwardRef((props, ref) => {
    const { className, children, ...rest } = props


    const { value, onValueChange, variant } = useTabs()

    const tabListClass = classNames(
        'tab-list',
        `tab-list-${variant}`,
        className
    )

    useEffect(() => {
        const tabList = document.getElementById('tabList');
        const selectedTab = tabList.querySelector('.tab-nav-active');
    
        if (selectedTab) {
            const tabListRect = tabList.getBoundingClientRect();
            const selectedTabRect = selectedTab.getBoundingClientRect();
            const firstTab = tabList.firstElementChild.firstElementChild;
            const lastTab = tabList.lastElementChild.lastElementChild;
            const lastTabText = tabList.lastElementChild.textContent; 
            const isTabOutOfView =
                selectedTabRect.right > tabListRect.right ||
                selectedTabRect.left < tabListRect.left;
    
            if (isTabOutOfView) {
                const scrollAmount =
                    selectedTabRect.left - tabListRect.left + 
                    selectedTabRect.width / 2 - 
                    tabListRect.width / 2;
    
                const maxScroll =
                    lastTab.getBoundingClientRect().right - 
                    tabListRect.left - 
                    tabListRect.width;
    
                const minScroll = 
                    firstTab.getBoundingClientRect().left -
                    tabListRect.left;
                const isLastTabSelected = selectedTab.textContent === lastTabText;
                const finalScroll = isLastTabSelected 
                    ? Math.max(10000, 0) // Ensure we don't scroll beyond max
                    : Math.min(Math.max(scrollAmount, minScroll + 1), maxScroll);
                tabList.scrollTo({
                    left: finalScroll,
                    behavior: 'smooth' // Optional for smooth scrolling
                });
            }
        }
    }, [children, value]);
    return (
        <div id='tabList' role="tablist" className={tabListClass} ref={ref} {...rest}>
            {children}
        </div>
    )
})

export default TabList