import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import TimeAgo from 'react-timeago';

import Navbar from '../../components/Navbar';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import { BreadcrumbHeader } from '../../components/PageHeader';
import SectionHeading from '../../components/SectionHeading';
import Button from '../../components/Button';
import { useNotification } from '../../components/Notification';

import { centsToDollars } from '../../utils/currency';
import {
  getMyBalance,
  getMyTransactions,
  startPayout,
  payout,
} from '../../api';

const YourBalance = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const showNotification = useNotification();

  const [balance, setBalance] = React.useState(0);
  const [transactions, setTransactions] = React.useState([]);
  const [processing, setProcessing] = React.useState(false);

  const loadData = React.useCallback(() => {
    getAccessTokenSilently()
      .then((token) =>
        Promise.all([getMyBalance(token), getMyTransactions(token)])
      )
      .then(([{ balance: myBalance }, myTransactions]) => {
        setBalance(myBalance);
        setTransactions(myTransactions);
      });
  }, [getAccessTokenSilently]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const handleStartPayout = async () => {
    try {
      setProcessing(true);

      const token = await getAccessTokenSilently();
      const { accountLink } = await startPayout(token, { email: user.email });
      window.location.replace(accountLink);
    } catch (error) {
      console.log(error);

      showNotification({
        title: 'Error',
        description: 'Sorry something went wrong!',
        type: 'error',
      });
    } finally {
      setProcessing(false);
    }
  };

  const handlePayout = React.useCallback(async () => {
    try {
      setProcessing(true);

      const token = await getAccessTokenSilently();
      await payout(token);

      showNotification({
        title: 'Successfully cashed out!',
        description: 'You have successfully cashed out your current balance!',
      });
      loadData();
    } catch (error) {
      console.log(error);

      const { type } = error.response?.data || {};

      if (type === 'ERR_CODE_NO_BALANCE') {
        showNotification({
          title: 'Error',
          description: 'Sorry your balance is insufficient!',
          type: 'error',
        });
      } else {
        showNotification({
          title: 'Error',
          description: 'Sorry something went wrong!',
          type: 'error',
        });
      }
    } finally {
      setProcessing(false);

      navigate('/balance', { replace: true });
    }
  }, [getAccessTokenSilently, navigate, showNotification, loadData]);

  React.useEffect(() => {
    if (searchParams.get('returning') && !processing) {
      handlePayout();
    }
  }, [processing, searchParams, handlePayout]);

  return (
    <>
      <Navbar />
      <Content>
      <div className="w-full max-w-7xl mx-auto space-y-3">

        <BreadcrumbHeader
          items={[
            { to: '/', label: 'Home' },
            { to: '#', label: 'Your Balance' },
          ]}
          title="Your Balance"
        />
        <div className="flex mt-10 gap-8 flex-col sm:flex-row">
          <aside className="w-72 max-w-full">
            <div className="rounded-lg shadow p-6 mb-4">
              <p className="text-sm leading-5 font-medium text-gray-500">
                Today's Balance
              </p>
              <p className="text-3xl leading-9 font-semibold text-gray-900 mt-1">
                {centsToDollars(balance)}
              </p>
            </div>
            <Button
              disabled={processing || !balance}
              onClick={handleStartPayout}
            >
              Cash Out
            </Button>
          </aside>
          <main className="flex-1">
            <SectionHeading>Recent transactions</SectionHeading>
            <ul className="divide-y divide-gray-200 mt-6 pr-3.5">
              {transactions.map(
                ({ id, amount, buyerId, storeTitle, completedAt }) => (
                  <li key={id} className="py-4">
                    <div className="flex space-x-3">
                      <div className="flex-1 space-y-1">
                        <div className="flex items-center justify-between">
                          {buyerId ? (
                            <h3 className="text-base font-medium text-emerald-600">
                              +{centsToDollars(amount)}
                            </h3>
                          ) : (
                            <h3 className="text-base font-medium text-red-600">
                              -{centsToDollars(amount)}
                            </h3>
                          )}
                          <p className="text-sm text-gray-500">
                            <TimeAgo date={completedAt} />
                          </p>
                        </div>
                        <p className="text-sm leading-5 text-gray-900">
                          {buyerId ? (
                            <>
                              <span className="font-bold">Sold</span>
                              <span>: {storeTitle} gift card</span>
                            </>
                          ) : (
                            <span className="font-bold">Cashed Out</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          </main>
        </div>
        </div>
      </Content>
      <Footer />
    </>
  );
};

export default withAuthenticationRequired(YourBalance);
