import React from 'react'
import classNames from 'classnames'
import { SIZES } from '../utils/constant'

const Line = (props) => {
    const { percent, size, children, strokeColor, isCard = false } = props

    const progressBackgroundClass = classNames(
        'progress-bg',
        size === SIZES.SM ? 'h-1.5' : 'h-2',
        `bg-${strokeColor}`
    )

    return (
        <div className='w-full'>
            {isCard ? (
                <div>
                    <div className="progress-wrapper flex flex-row items-center">
                        <p className="text-[#111927] font-bold text-2xl mr-2">
                            {children}{' '}
                        </p>
                        <div className="progress-inner">
                            <div
                                className={progressBackgroundClass}
                                style={{ width: `${percent}%` }}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="progress-wrapper">
                        <div className="progress-inner">
                            <div
                                className={progressBackgroundClass}
                                style={{ width: `${percent}%` }}
                            />
                        </div>
                    </div>
                    {children}
                </div>
            )}
        </div>
    )
}

export default Line
