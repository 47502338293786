import React from "react";
import { Skeleton } from "../ui";

const SkeletonLoader = ({
  height = 20,
  length = 10,
  showAvatar = false,
  avatarColumn = 2,
  avatarWidth = 45,
  avatarHeight = 45,
  itemDirection = "items-center",
  width = "100%",
  skeletonGap = 10, // New prop for gap between skeletons (in pixels)
  showTwoInLine = false, // New prop to determine layout
}) => {
  return (
    <>
      {Array.from({ length }).map((_, index) => (
        <div key={index} style={{ marginBottom: skeletonGap }}>
          {showAvatar ? (
            <div className={`flex ${itemDirection} gap-2`}>
              <Skeleton
                width={avatarWidth}
                height={avatarHeight}
                variant="circle"
              />
              <div className="flex flex-col gap-2">
                {Array.from({ length: avatarColumn }).map((_, avatarIndex) => (
                  <Skeleton
                    key={avatarIndex}
                    height={height}
                    style={{ width }}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className={`flex ${showTwoInLine ? "justify-between" : "flex-col"}`}>
              {showTwoInLine ? (
                <>
                  <Skeleton height={height} style={{ width }} />
                  <Skeleton height={height} style={{ width }} />
                </>
              ) : (
                <Skeleton height={height} style={{ width }} />
              )}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default SkeletonLoader;
